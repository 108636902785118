define("discourse/plugins/discourse-events/discourse/models/event", ["exports", "@ember/array", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/topic", "discourse/plugins/discourse-events/discourse/models/source"], function (_exports, _array, _object, _ajax, _ajaxError, _topic, _source) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Event = _object.default.extend();
  Event.reopenClass({
    list() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)("/admin/plugins/events/event", {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(data) {
      return (0, _ajax.ajax)("/admin/plugins/events/event", {
        type: "DELETE",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    eventsArray(events) {
      return (0, _array.A)(events.map(event => {
        let source = _source.default.create(event.source);
        let topics = (0, _array.A)(event.topics.map(t => _topic.default.create(t)));
        return Object.assign(event, {
          source,
          topics
        });
      }));
    }
  });
  var _default = _exports.default = Event;
});