define("discourse/plugins/discourse-events/discourse/components/modal/add-event", ["exports", "@ember/component", "@ember/object", "I18n", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _object, _I18n, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="add-event-modal"
    @title={{this.title}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      <EventForm @event={{model.event}} @updateEvent={{action "updateEvent"}} />
    </:body>
    <:footer>
      <DButton
        class="btn-primary"
        @action={{action "saveEvent"}}
        @label="add_event.event_add"
      />
      <a href class="clear" {{on "click" this.clear}}>{{i18n
          "add_event.event_clear"
        }}</a>
    </:footer>
  </DModal>
  */
  {
    "id": "+w0SSqj+",
    "block": "[[[8,[39,0],[[24,0,\"add-event-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[30,0,[\"title\"]],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@event\",\"@updateEvent\"],[[30,0,[\"model\",\"event\"]],[28,[37,2],[[30,0],\"updateEvent\"],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,2],[[30,0],\"saveEvent\"],null],\"add_event.event_add\"]],null],[1,\"\\n    \"],[11,3],[24,6,\"\"],[24,0,\"clear\"],[4,[38,4],[\"click\",[30,0,[\"clear\"]]],null],[12],[1,[28,[35,5],[\"add_event.event_clear\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-events/discourse/components/modal/add-event.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"@closeModal\"],false,[\"d-modal\",\"event-form\",\"action\",\"d-button\",\"on\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/modal/add-event.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    title: _I18n.default.t("add_event.modal_title"),
    clear() {
      event?.preventDefault();
      this.set("bufferedEvent", null);
    },
    actions: {
      saveEvent() {
        if (this.valid) {
          this.get("model.update")(this.bufferedEvent);
          this.closeModal();
        } else {
          this.set("flash", _I18n.default.t("add_event.error"));
        }
      },
      updateEvent(event, valid) {
        this.set("bufferedEvent", event);
        this.set("valid", valid);
      }
    }
  }, [["method", "clear", [_object.action]]])));
});