define("discourse/plugins/discourse-events/discourse/models/source", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Source = _object.default.extend();
  Source.reopenClass({
    all() {
      return (0, _ajax.ajax)("/admin/plugins/events/source").catch(_ajaxError.popupAjaxError);
    },
    update(source) {
      return (0, _ajax.ajax)(`/admin/plugins/events/source/${source.id}`, {
        type: "PUT",
        contentType: "application/json",
        data: JSON.stringify({
          source
        })
      }).catch(_ajaxError.popupAjaxError);
    },
    import(source) {
      return (0, _ajax.ajax)(`/admin/plugins/events/source/${source.id}`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(source) {
      return (0, _ajax.ajax)(`/admin/plugins/events/source/${source.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = Source;
});